<template>
  <router-link :to="link" class="c-cardMember">
    <div class="c-cardMember_status" :class="statusClass">
      {{ statusText }}
    </div>
    <!-- <img class="c-cardMember_img" :src="img" /> -->
    <div class="c-cardMember_txt">
      <p class="c-cardMember_txt_ja">{{ nameJa }}</p>
      <p class="c-cardMember_txt_en">{{ nameEn }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "CardMember",
  props: {
    nameJa: String,
    nameEn: String,
    status: Number,
    img: {
      type: String,
      default: "https://placehold.jp/200x200.png",
    },
    link: String,
  },
  data: () => ({
    statusText: "",
    statusClass: "",
  }),
  mounted() {
    switch (this.status) {
      case 0:
        this.statusText = "不在";
        this.statusClass = "c-cardMember_status-absence";
        break;
      case 1:
        this.statusText = "在席中";
        this.statusClass = "c-cardMember_status-attendance";
        break;
      case 2:
        this.statusText = "来客中";
        this.statusClass = "c-cardMember_status-withVisitor";
        break;
      default:
        this.statusText = "---";
        this.statusClass = "";
    }
  },
};
</script>

<style scoped lang="scss">
.c-cardMember {
  position: relative;
  display: flex;
  align-items: center;
  padding: 24px 16px 8px;
  border-radius: 11px;
  width: calc(25% - 12px);
  height: 92px;
  box-shadow: 0px 0px 6px #00000029;

  &_status {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-bottom: 1px;
    width: 57px;
    height: 19px;
    line-height: 1;
    font-size: 12px;
    color: $white;

    &-attendance {
      background-color: $blue;
    }

    &-absence {
      background-color: $gray;
    }

    &-withVisitor {
      background-color: $orange;
    }
  }

  &_img {
    border-radius: 11px;
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  &_txt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    &_ja {
      line-height: 1;
      font-size: 14px;
      font-weight: 500;
      /* 3点リーダー */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_en {
      @include opensans;
      line-height: 1;
      font-size: 14px;
      font-weight: 500;
      /* 3点リーダー */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
