<template>
  <div class="p-memberTop">
    <div class="p-memberTop_company">
      <img class="p-memberTop_company_logo" :src="logo" />
      <p class="p-memberTop_company_txt">
        {{ name }}
      </p>
    </div>

    <p class="p-memberTop_txt">ユーザーを選択してください。</p>

    <div class="p-memberTop_member">
      <CardMember
        v-for="(v, i) in members"
        :key="i"
        :nameJa="v.ja"
        :nameEn="v.en"
        :status="v.status"
        :img="v.img"
        :link="`/member_status?id=${v.id}&status=${v.status}`"
      />
    </div>
  </div>
</template>

<script>
/*
member status table
  0: 不在 (gray)
  1: 在籍中 (blue)
  2: 来客中 (orange)
*/

import axios from "axios";

import CardMember from "@/components/CardMember.vue";

export default {
  name: "MemberTop",
  components: {
    CardMember,
  },
  data: () => ({
    logo: "https://placehold.jp/200x200.png",
    name: "-----",
    members: [],
  }),
  async mounted() {
    const me = localStorage.getItem("me");
    const { data } = await axios.get("/companies.json?" + new Date().getTime());
    const company = data.filter((x) => x.id === me)[0];

    this.logo = company.logo;
    this.name = company.name;
    this.members = company.members;
  },
};
</script>

<style scoped lang="scss">
.p-memberTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px auto 0;

  &_company {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    width: 100%;

    &_logo {
      width: 60px;
      height: 60px;
    }

    &_txt {
      margin-left: 16px;
    }
  }

  &_txt {
    margin-top: 40px;
    text-align: center;
    font-size: 16px;
  }

  &_member {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 30px auto 0;
    padding: 10px;
    width: 850px;
    height: 500px;
    overflow-y: auto;

    .c-cardMember {
      &:not(:nth-child(4n-3)) {
        margin-left: 16px;
      }

      &:nth-child(n + 5) {
        margin-top: 16px;
      }
    }
  }
}
</style>
